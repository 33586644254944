import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

window.vFrontPage = () => {
	gsap.registerPlugin(ScrollTrigger, SplitText);

	let mobile = window.matchMedia("(max-width: 768px)");
	const header = document.querySelector('header');
	const mobileNav = document.querySelector('#mobile-nav');
	const hero = document.querySelector('.v-fp-hero');
	const mark = hero.querySelector('.v-fp-hero__graphic-mark');
	const dots = hero.querySelector('.v-fp-hero__graphic-dots');
	const title = hero.querySelector('.v-fp-hero__content-title');
	const heroOverlay = hero.querySelectorAll('.v-fp-hero__img-wrap--mobile .v-fp-hero__img--overlay, .v-fp-hero__img-wrap--desktop .v-fp-hero__img--overlay');
	const heroImgBlue = hero.querySelectorAll('.v-fp-hero__img-wrap--mobile .v-fp-hero__img--start, .v-fp-hero__img-wrap--desktop .v-fp-hero__img--start');
	const industryItems = document.querySelectorAll('.v-fp-industry__item');
	const serviceItems = document.querySelectorAll('.v-fp-services__item');
	const sticky = document.querySelector('.v-fp-sticky-nav');
	const stickyTitle = sticky.querySelector('.v-fp-sticky-nav__title');

	let stickyHeight = 88;
	let stickyExpandPause = 500;
	let isAnimatingMark = false;
	let hasScrolled = false;
	let ease = 'power4.out';
	let resizeTimer;
	let scrollDepth;
	let i = 0
	let j = 0

	if (vAlert) {
		return;
	}

	const splitHero = new SplitText(title, {type: 'chars,words', wordsClass: 'v-fp-hero__title-word'});
	const splitSticky = new SplitText(stickyTitle, {type: 'chars,words', wordsClass: 'v-fp-sticky-nav__title-word'});

	// gsap initial states
	gsap.set(splitHero.chars, { opacity: 0 });
	gsap.set(splitSticky.chars, { opacity: 0 });
	gsap.set(stickyTitle, { opacity: 1 });

	mark.classList.add('v-fp-hero__graphic-mark--enter');
	dots.classList.add('v-fp-hero__graphic-dots--enter');
	title.classList.add('v-fp-hero__content-title--active');

	gsap.to(splitHero.chars, {
		opacity: 1,
		delay: .2,
		stagger: {
			amount: 1,
		},
		onComplete: () => {
			// wait 3s (graphicExitDuration) then stagger out
			gsap.to(splitHero.chars, {
				delay: graphicExitDuration - .5,
				opacity: 0,
				stagger: {
					amount: .5,
				},
			});
		}
	});


	// timing vars
	const graphicEnterDuration = parseInt(window.getComputedStyle(document.documentElement)
	.getPropertyValue('--graphic-enter-duration'), 10);
	const graphicPauseDuration = 5;
	const graphicExitDuration = 3;
	const splitStickyEnter = graphicPauseDuration + graphicExitDuration;

	/**
	* HERO GREY OVERLAY
	*
	*/
	const heroImgTl = gsap.timeline();
	heroImgTl.to(heroOverlay, {
		delay: graphicPauseDuration,
		duration: graphicExitDuration,
		scaleX: 0,
		ease
	});

	/**
	* SPLIT TITLE ANIMATION
	*
	*/
	// title.classList.add('v-fp-hero__content-title--active');
	// gsap.to(splitHero.chars, {
	// 	opacity: 1,
	// 	delay: .2,
	// 	stagger: {
	// 		amount: 1,
	// 	},
	// 	onComplete: () => {
	// 		// wait 3s (graphicExitDuration) then stagger out
	// 		gsap.to(splitHero.chars, {
	// 			delay: graphicExitDuration - .5,
	// 			opacity: 0,
	// 			stagger: {
	// 				amount: .5,
	// 			},
	// 		});
	// 	}
	// });

	/**
	* MARK / DOTS ANIMATION
	*
	*/
	const tlGraphic =  gsap.timeline();
	tlGraphic.to(mark, {
		duration: graphicPauseDuration,
		onComplete: () => {
			dots.classList.add('v-fp-hero__graphic-dots--exit');

			if (getScrollDepth() > 0) {
				animateStickyFromBottom();
				return false;
			} else if (hasScrolled) {
				// this check is for if user triggers bottom
				// sticky nav, but then scrolls back up.

				// fade mark out
				gsap.to(mark, {
					opacity: 0,
					duration: .3,
				});

				return;
			}

			mark.classList.add('v-fp-hero__graphic-mark--exit');
		}
	}).to(mark, {
		duration: graphicExitDuration,
		onUpdate: function() {

			// if at any point user scroll while
			// mark is animating bail and
			// run other animation
			if (getScrollDepth() > 0) {
				isAnimatingMark = true;
				animateStickyFromBottom();

				// fade mark out
				gsap.to(mark, {
					opacity: 0,
					duration: .3,
				});

				return;
			}

			//! .7 is a magic number.
			// may need updating if the sticky
			// nav is not timed perfectly
			// w/ the animation mark
			if (this.progress() > .7) {
				// dont run blue to black sticky nav
				// animation if the user has scroll
				if (getScrollDepth() > 0) {
					return;
				}
				animateBlueOverlay();
			}
		},
		onComplete: () => {
			isAnimatingMark = false;

			setTimeout(() => {
				mark.classList.remove('v-fp-hero__graphic-mark--exit');
				dots.classList.remove('v-fp-hero__graphic-dots--exit');
				// these classes are given bc on browser
				// resize these elements jump around
				mark.classList.add('v-fp-hero__graphic-mark--hide');
				dots.classList.add('v-fp-hero__graphic-dots--hide');
			}, stickyExpandPause);
		}
	});

	/**
	* HERO BLUE OVERLAY
	*
	*/
	function animateBlueOverlay(pause) {

		if (j === 0) {
			animateSticky();
			gsap.to(heroImgBlue, {
				duration: 3,
				delay: pause ? `${((pause + stickyExpandPause) / 1000)}` : 0,
				"--clip": "inset(0% 100% 0% 0%)",
				ease: "power4.inOut",
			});
		}
		j++;
	}

	/**
	* STICKY ANIMATION
	* SHOW / EXPAND
	*
	*/
	function animateSticky(stickyExpandPause) {

		// add animation class
		gsap.to(sticky, {
			duration: 2.5,
			onComplete: () => {
				// pause right before
				// sticky expands
				setTimeout(() => {
					// animate text
					splitStickyAnimation(750);
					// expand class
					sticky.classList.add('v-fp-sticky-nav--mid');
				}, stickyExpandPause);
			}
		}, 0)
	}

	/**
	* STICKY ANIMATION FROM WINDOW BOTTOM
	* USED IF USER HAS ALREADY SCROLLED
	*
	*/
	function animateStickyFromBottom() {

		if (i > 0) {
			return;
		}

		if (!isAnimatingMark) {
			mark.classList.add('v-fp-hero__graphic-mark--exit-full');
		}

		animateBlueOverlay(2000);

		gsap.timeline({
			ease: 'power1.easeOut',
			scrollTrigger: {
				trigger: hero,
				start: `top -${stickyHeight}`, // scroll ${stickyHeight}px down (height of sticky nav)
				// markers: {startColor: "blue", endColor: "pink", fontSize: "22px", indent: '122'},
				onEnter: () => {
					// animate text
					splitStickyAnimation();

					// expand class
					sticky.classList.add('v-fp-sticky-nav--mid');
					// expand full-width class
					sticky.classList.add('v-fp-sticky-nav--end');
				},
				onLeaveBack: () => {
					// remove full-width class
					sticky.classList.remove('v-fp-sticky-nav--end');
				}
			}
		});

		i++;
	}

	/**
	* SCROLL TO SHOW FULL WIDTH
	*
	*/
	const tlStickyScroll = gsap.timeline({
		ease: 'power1.easeOut',
		scrollTrigger: {
			trigger: hero,
			start: `top -${stickyHeight}`, // scroll ${stickyHeight}px down (height of sticky nav)
			// markers: {startColor: "blue", endColor: "pink", fontSize: "22px", indent: '122'},
			onEnter: () => {
				// expand full-width class
				sticky.classList.add('v-fp-sticky-nav--shorten-trans');
				sticky.classList.add('v-fp-sticky-nav--end');
				sticky.classList.add('v-fp-sticky-nav--mid');
			},
			onLeaveBack: () => {
				// remove full-width class
				sticky.classList.remove('v-fp-sticky-nav--end');
			}
		}
	});

	/**
	* SPLIT STICKY TITLE ANIMATION
	*
	*/
	function splitStickyAnimation(delay) {

		if ( stickyTitle.classList.contains('v-fp-sticky-nav__title-word--active') ) {
			return;
		}

		stickyTitle.classList.add('v-fp-sticky-nav__title-word--active');

		gsap.to(splitSticky.chars, {
			opacity: 1,
			delay: delay ? (delay / 1000) : 0,
			stagger: {
				amount: .5,
			},
			onStart: () => {
				sticky.querySelector('.v-fp-sticky-nav__cta-wrap').classList.add('v-fp-sticky-nav__cta-wrap--active');
			},
			onComplete: () => {
				sticky.classList.add('v-fp-sticky-nav--shorten-trans');
			}
		});
	}

	/**
	* SECTION ENTER ANIMATIONS
	*
	*/
	gsap.to(industryItems, {
		ease: 'power1.easeOut',
		scrollTrigger: {
			trigger: industryItems,
			start: 'top 90%',
			end: 'top 90%',
			scrub: false,
			onEnter: () => {
				gsap.to(industryItems, {
					stagger: {
						amount: .3,
					},
					y: 0,
					opacity: 1,
					overwrite: 'auto'
				})
			},
			onLeaveBack: () => {
				gsap.to(industryItems, {
					stagger: {
						amount: .4,
					},
					y: 32,
					opacity: 0,
					overwrite: 'auto'
				})
			}
		},
	});

	gsap.to(serviceItems, {
		ease: 'power1.easeOut',
		scrollTrigger: {
			trigger: serviceItems,
			start: 'top 90%',
			end: 'top 90%',
			scrub: false,
			onEnter: () => {
				gsap.to(serviceItems, {
					stagger: {
						amount: .3,
					},
					y: 0,
					opacity: 1,
					overwrite: 'auto'
				})
			},
			onLeaveBack: () => {
				gsap.to(serviceItems, {
					stagger: {
						amount: .4,
					},
					y: 32,
					opacity: 0,
					overwrite: 'auto'
				})
			}
		},
	});

	/**
	* SCROLL Y POSITION
	*
	* return window scroll y depth
	*/
	const getScrollDepth = () => {
		let scrollDepth = window.scrollY;

		if (scrollDepth >= stickyHeight) {
			hasScrolled = true;
			// animate text
			splitStickyAnimation();
		}
		return scrollDepth;
	};
	window.addEventListener('scroll', getScrollDepth);

	/**
	* update mobile var on resize
	*
	*/
	window.addEventListener("resize", () => {
		mobile = window.matchMedia("(max-width: 768px)");
	});
}


if ( 'loading' !== document.readyState ) {
	vFrontPage();
} else {
	document.addEventListener( 'DOMContentLoaded', vFrontPage );
}